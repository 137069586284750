<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="certification-view"
  >
    <div>
      <h2 class="mg-y-2">
        <span class="cr-pointer" @click="$router.push(`/certification`)">
          Certification
        </span>
        >
        <!-- <span class="cr-pointer" @click="$router.push(`/certification/add`)">
          Create Certificate
        </span>
        > -->
        <span class="color-light-blue">View Certificate</span>
      </h2>
      <div :gutter="15" class="is-flex ai-center js-end mg-t-4">
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitPrint()"
          :loading="loadingPrint"
          >Print</el-button
        >
      </div>
    </div>
    <div class="show-preview">
      <PreviewCertification :form="form" :viewOnly="true" />
    </div>

    <Html2pdf :generatePdf="generatePdf" @onProgress="onProgress">
      <PreviewCertification :form="form" :viewOnly="true" />
    </Html2pdf>

    <div class="text-left mg-t-5">
      <el-button round @click="$router.push(`/certification`)">
        <span class="font-14"> Cancel </span>
      </el-button>
    </div>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import AddCertification from "@/components/certification/AddCertification";
import PreviewCertification from "@/components/certification/PreviewCertification";
import Html2pdf from "@/components/pdf/Html2pdf";
import { mapState } from "vuex";

export default {
  components: {
    AddCertification,
    PreviewCertification,
    Html2pdf,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  mounted() {
    this.getCertificationById();
  },
  data() {
    return {
      loading: false,
      loadingPrint: false,
      form: {},
      generatePdf: false,
      progress: 0,
    };
  },
  methods: {
    getCertificationById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`certification/` + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            let finByIdCer = res.data.obj.certification.find(
              (cer) => cer._id.toString() == this.$route.params.id
            );
            let cer = null;
            if (finByIdCer != undefined) {
              cer = finByIdCer;
            }
            this.form = {
              ...cer,
              companyName: res.data.obj.companyName,
              registerAddress: res.data.obj.address,
              companyType: res.data.obj.companyType,
              // ...res.data.obj.certification[0],
              _id: res.data.obj._id,
            };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitPrint() {
      this.loadingPrint = true;
      setTimeout(() => {
        this.generatePdf = true;
      }, 100);
    },
    preview() {
      this.dialogPreview = true;
    },
    onProgress(progress) {
      this.progress = progress;
      if (this.progress == 100) {
        this.loadingPrint = false;
        this.generatePdf = false;
      }
    },
  },
};
</script>
